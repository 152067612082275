<template>
    <div>
        <UnreadMessage />

        <h2>{{text.user_home_subtitle}}</h2>

        <router-link :to="{ name: 'UserAdvertsNew'}" :title="text.create_new_advert" class="btn btn-primary btn-smyspace">{{text.create_new_advert}}</router-link>
        <router-link :to="{ name: 'UserAdvertsList'}" :title="text.view_all_adverts" class="btn btn-secondary">{{text.view_all_adverts}}</router-link>

        <div class="panel panel-primary" v-if="activeTableData.length > 0">
            <div class="panel-heading">
                <div class="panel-heading-text">{{text.user_live_adverts_panel}} <!--<span>A note here</span>--></div>
            </div>
            <div class="panel-content panel-content-grid">
                <section class="panel-content-grid-item" v-for="row in activeTableData" :key="row.id">
                    <div class="panel-content-grid-item-inner">
                        <h1 class="panel-content-grid-item-title">{{row.name}}</h1>
                        <div class="image image-border">
                            <img v-auth-src="buildPreviewImagePath(row.id)" :alt="row.name | cleanString" />
                        </div>
                        <div class="panel-content-actions">
                            <router-link :to="{ name: 'UserAdvertsView', params: { 'id' : row.id } }" :title="text.view + ' ' + row.name" class="btn btn-secondary btn-small btn-smyspace">{{text.view}}</router-link>
                            <router-link :to="{ name: 'UserAdvertsExport', params: { 'id' : row.id } }" :title="text.export + ' ' + row.name" class="btn btn-tertiary btn-small btn-noyspace">{{text.export}}</router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import store from "@/store"

    import {advertActionsMixin} from "@/views/mixins/advertActions";
    import {advertExportActionsMixin} from "@/views/mixins/advertExportActions";
    import {formatContentMixin} from "@/views/mixins/formatContent";

    import {MESSAGE_TYPE_ERROR,MEDIA_ROOT} from "@/shared/consts";

    import UnreadMessage from "@/components/unreadmessage";

    export default {
        name: 'Home',
        mixins: [advertActionsMixin, advertExportActionsMixin, formatContentMixin],
        components: {UnreadMessage},
        data() {
            return {
                activeTableData: []
            }
        },
        computed: {
            ...mapState(['user','pageTitle']), // imports from store
            text() {
                return lang;
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
        },
        mounted() {
            log(["User home mounted", this.user.name]);

            // Set Page Title
            this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.user.name) });
        },
        created() {
            log(["User home created", this.$route.params.message]);
            if(this.$route.params.message === "notadmin"){
                store.dispatch('addSystemMessageAction', {
                    'type' : MESSAGE_TYPE_ERROR,
                    'message' : this.text.message_not_admin
                });
            }

            this.loadActiveAdvertsListCurrentUser();
        }
    }
</script>
