<template>
    <div class="unread-messages" v-if="unreadMessages.messages.length > 0">
    <section class="unread-message" v-for="unreadMessage in unreadMessages.messages" :key="unreadMessage.id">
        <div class="unread-message-inner body-content">
            <h1>{{text.unread_message_panel_title}}</h1>
            <div class="unread-message-list">
                <router-link :to="{ name: 'UserMessagesView', params: { 'id' : unreadMessage.id } }" :title="text.view + ' ' + unreadMessage.headline" class="unread">{{unreadMessage.headline}}</router-link>

                <span v-html="unreadMessage.body"></span>
            </div>

            <a href="#" :title="text.mark_message_as_read" class="btn btn-alt" @click="markCurrentMessageAsRead($event, unreadMessage.id)" @keyup.enter="markCurrentMessageAsRead($event, unreadMessage.id)">{{text.mark_message_as_read}}</a>
            <!--
            <router-link :to="{ name: 'UserMessagesView', params: { 'id' : unreadMessage.message.id } }" :title="text.view + ' ' + unreadMessage.message.headline" class="btn btn-secondary">{{text.view_latest_unread_message}}</router-link>
            <router-link :to="{ name: 'UserMessagesList'}" :title="text.view + ' ' + unreadMessage.message.headline" class="btn btn-tertiary">{{text.view_all_messages}}</router-link>
            -->
        </div>
    </section>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {log} from "@/shared/utils";

    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    export default {
        name: "UnreadMessage",
        mixins: [messagesActionsMixin],
        computed: {
            text() {
                return lang;
            }
        },
        methods: {
            async markCurrentMessageAsRead(e, messageID) {
                e.preventDefault();

                // make sure if this message is marked unread
                if(await this.markMessageAsRead(messageID))
                {
                    // are there any more unread messages?
                    this.loadUserUnreadMessages();
                }
            }
        },
        mounted() {
            log("Unread message mounted");

            this.loadUserUnreadMessages();
        },
    }
</script>